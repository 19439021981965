import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "creator-content-cart-page__video-grid" }
const _hoisted_2 = { class: "flex flex-wrap justify-center sm:justify-between gap-x-8 gap-y-4 mt-32 mb-36" }
const _hoisted_3 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_LayoutEmptyPage = _resolveComponent("LayoutEmptyPage")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_CreatorContentMediaItem = _resolveComponent("CreatorContentMediaItem")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_ModalPrompt = _resolveComponent("ModalPrompt")!
  const _component_LoadingOutlined = _resolveComponent("LoadingOutlined")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    "back-button": "",
    onOnBack: _ctx.$router.back,
    class: "creator-content-cart-page"
  }, {
    default: _withCtx(() => [
      (_ctx.cartListLoading)
        ? (_openBlock(), _createBlock(_component_a_skeleton, {
            key: 0,
            active: ""
          }))
        : (_ctx.isCartEmpty)
          ? (_openBlock(), _createBlock(_component_LayoutEmptyPage, {
              key: 1,
              title: _ctx.t('No items on your cart.')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: _ctx.routeNames.creatorContentSearch })))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Search Creator Content")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["title"]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createVNode(_component_a_typography_title, {
                level: 2,
                class: "mt-14 mb-12"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Step 1: Finalize your choices")), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cartList, (cartItem) => {
                  return (_openBlock(), _createBlock(_component_ModalPrompt, {
                    key: cartItem.id,
                    actions: [
              {
                title: _ctx.t('Cancel'),
              },
              {
                title: _ctx.t('Confirm'),
                action: () => _ctx.removeFromCart(cartItem?.id),
                type: 'primary',
              },
            ],
                    icon: "",
                    title: _ctx.t('Removing item')
                  }, {
                    trigger: _withCtx(({ open }) => [
                      _createVNode(_component_CreatorContentMediaItem, {
                        item: cartItem,
                        onOnRemove: open,
                        "hide-add-to-cart": "",
                        "show-delete-button": "",
                        "fixed-ratio": ""
                      }, null, 8, ["item", "onOnRemove"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_paragraph, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Are you sure you want to remove this item from your cart?")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["actions", "title"]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ModalPrompt, {
                  actions: [
            {
              title: _ctx.t('Cancel'),
            },
            {
              title: _ctx.t('Confirm'),
              action: _ctx.handleClearCart,
              type: 'primary',
            },
          ],
                  icon: "error",
                  title: _ctx.t('Clearing cart')
                }, {
                  trigger: _withCtx(({ open }) => [
                    _createVNode(_component_a_button, {
                      size: "small",
                      onClick: open,
                      class: "h-11 bg-transparent w-full sm:w-auto"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Clear Cart")), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_a_typography_paragraph, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Are you sure you want to remove all items on your cart?")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["actions", "title"]),
                _createVNode(_component_a_button, {
                  size: "small",
                  type: "primary",
                  "html-type": "submit",
                  onClick: _ctx.handleCheckout,
                  class: "h-11 w-full sm:w-auto"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Order Now")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _createVNode(_component_a_modal, {
                visible: _ctx.cartListUpdating,
                width: "100px",
                footer: null,
                closable: false,
                centered: ""
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_LoadingOutlined, { class: "text-4xl m-auto" })
                  ])
                ]),
                _: 1
              }, 8, ["visible"])
            ], 64))
    ]),
    _: 1
  }, 8, ["onOnBack"]))
}