import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Suspense as _Suspense, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "creator-content-media-item",
  ref: "mediaItemRef"
}
const _hoisted_2 = { class: "creator-content-media-item__cover-play-button" }
const _hoisted_3 = { class: "creator-content-media-item__cover-video" }
const _hoisted_4 = { class: "creator-content-media-item__content h-full" }
const _hoisted_5 = { class: "h-full flex flex-col justify-between" }
const _hoisted_6 = { class: "creator-content-media-item__content-header" }
const _hoisted_7 = { class: "creator-content-media-item__brand-name" }
const _hoisted_8 = {
  key: 0,
  class: "creator-content-media-item__creator"
}
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_VimeoEmbedded = _resolveComponent("VimeoEmbedded")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_a_skeleton, {
          key: 0,
          active: "",
          width: "100%"
        }))
      : (_openBlock(), _createBlock(_component_a_card, {
          key: 1,
          class: "creator-content-media-item__card",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-click', _ctx.item.id)))
        }, {
          cover: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass([
            'creator-content-media-item__cover',
            _ctx.isPhoto ? 'photo' : 'video',
          ])
            }, [
              (_ctx.isPhoto)
                ? (_openBlock(), _createBlock(_component_a_image, {
                    key: 0,
                    alt: _ctx.item.brand,
                    src: _ctx.itemObject.photoThumbnail,
                    preview: { src: _ctx.itemObject.media },
                    fallback: _ctx.fallbackImageSrc,
                    class: _normalizeClass(["creator-content-media-item__cover-image", [
              _ctx.fixedRatio &&
                'creator-content-media-item__cover-image--fixed-ratio',
            ]])
                  }, null, 8, ["alt", "src", "preview", "fallback", "class"]))
                : (_openBlock(), _createBlock(_component_a_image, {
                    key: 1,
                    preview: { visible: false, onVisibleChange: _ctx.onVisibleChange },
                    alt: _ctx.item.brand,
                    src: _ctx.getVimeoThumbnail(_ctx.itemObject.media) ?? '#',
                    fallback: _ctx.fallbackImageSrc,
                    class: _normalizeClass(["creator-content-media-item__cover-image", [
              _ctx.fixedRatio &&
                'creator-content-media-item__cover-image--fixed-ratio',
            ]])
                  }, {
                    previewMask: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_AppIcon, { name: "videoPlayButtonOverlay" })
                      ])
                    ]),
                    _: 1
                  }, 8, ["preview", "alt", "src", "fallback", "class"])),
              _createVNode(_component_a_modal, {
                class: "creator-content-media-item__cover-video-modal",
                visible: _ctx.videoPreview,
                "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.videoPreview) = $event)),
                centered: "",
                "destroy-on-close": "",
                closable: false,
                footer: null,
                width: "auto"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    (_openBlock(), _createBlock(_Suspense, { onResolve: _ctx.handleInjectEmbedScripts }, {
                      default: _withCtx(() => [
                        _createVNode(_component_VimeoEmbedded, {
                          "embed-src": _ctx.itemObject.media
                        }, null, 8, ["embed-src"])
                      ]),
                      _: 1
                    }, 8, ["onResolve"]))
                  ])
                ]),
                _: 1
              }, 8, ["visible"])
            ], 2),
            (_ctx.item.videoDuration)
              ? (_openBlock(), _createBlock(_component_a_badge, {
                  key: 0,
                  class: "creator-content-media-item__video-duration",
                  standalone: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formatDateFromSeconds(_ctx.item.videoDuration, "mm:ss")), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("b", null, _toDisplayString(_ctx.item.brand), 1)
                    ]),
                    _createVNode(_component_a_typography_title, {
                      level: 5,
                      class: "creator-content-media-item__price"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.n(_ctx.itemObject.price, "currency", "ph")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  (_ctx.item.creatorName)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_i18n_t, { keypath: "by {name}" }, {
                          name: _withCtx(() => [
                            (_ctx.item.publicCreatorLink)
                              ? (_openBlock(), _createBlock(_component_router_link, {
                                  key: 0,
                                  to: _ctx.getRelativePathFromUrl(_ctx.item.publicCreatorLink)
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.item.creatorName), 1)
                                  ]),
                                  _: 1
                                }, 8, ["to"]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.item.creatorName), 1))
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (!_ctx.hideAddToCart)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 0,
                      disabled: _ctx.selected,
                      type: "primary",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-purchase', _ctx.item.id))),
                      class: "creator-content-media-item__purchase-button"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AppIcon, {
                          class: "creator-content-media-item__cart-icon",
                          name: "cartPlusWhite"
                        }),
                        _createTextVNode(" " + _toDisplayString(!_ctx.selected ? _ctx.t("Add to cart") : _ctx.t("In cart")), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            (_ctx.showDeleteButton)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 0,
                  shape: "circle",
                  class: "creator-content-media-item__delete-button",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('on-remove', _ctx.item.id)), ["stop"]))
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_AppIcon, { name: "trash" })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
  ], 512))
}