
import { defineComponent, PropType, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { CreatorContentSubmissions_creatorContentSubmissions_CreatorContentSubmissionResults_results } from "@/api/creatorContent/__generated__/CreatorContentSubmissions";
import { ContentType } from "@/../__generated__/globalTypes";
import { createVimeoEmbeddedScript } from "@/shared/utils/vimeo";
import { useVimeoEmbed } from "@/shared/composables/useVimeoEmbed";
import { fallbackSrc as fallbackImageSrc } from "@/shared/utils/constants";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import VimeoEmbedded from "@/shared/components/VimeoEmbedded.vue";
import { getCreatorContentDetails } from "@/shared/utils/creatorContentHelper";
import { getRelativePathFromUrl } from "@/shared/utils/routerUtils";
import { formatDateFromSeconds } from "@/shared/utils/date";

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<CreatorContentSubmissions_creatorContentSubmissions_CreatorContentSubmissionResults_results>,
      required: true,
    },
    selected: Boolean,
    loading: Boolean,
    showDeleteButton: Boolean,
    hideAddToCart: Boolean,
    /** For thumbnail to use 9:16 */
    fixedRatio: Boolean,
  },
  emits: ["on-click", "on-purchase", "on-remove"],
  setup(props) {
    const { t, n } = useI18n();
    const mediaItemRef = ref();
    const videoPreview = ref(false);
    const isPhoto = computed(
      () => props.item?.contentType === ContentType.PHOTO
    );
    const itemObject = computed(() => {
      return getCreatorContentDetails(props.item);
    });
    const handleInjectEmbedScripts = () => {
      try {
        mediaItemRef.value.prepend(createVimeoEmbeddedScript());
      } catch (error) {
        console.log("Something went wrong when trying to inject embed scripts");
      }
    };
    const { getVimeoThumbnail } = useVimeoEmbed();
    const onVisibleChange = () => {
      videoPreview.value = !videoPreview.value;
    };
    return {
      t,
      n,
      isPhoto,
      itemObject,
      handleInjectEmbedScripts,
      getVimeoThumbnail,
      onVisibleChange,
      videoPreview,
      fallbackImageSrc,
    };
  },
  methods: {
    getRelativePathFromUrl,
    formatDateFromSeconds,
  },
  components: {
    AppIcon,
    VimeoEmbedded,
  },
});
