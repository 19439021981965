import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "xl:mt-24"
}
const _hoisted_2 = { class: "block max-w-full text-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReloadOutlined = _resolveComponent("ReloadOutlined")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_ctx.status !== 'available')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.t(
            "The video is still processing. It will be available here in a while."
          )), 1),
          (!_ctx.hideRefresh)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_a_button, {
                  type: "link",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-refresh')))
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_ReloadOutlined)
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" " + _toDisplayString(_ctx.t("Refresh")), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.useOembed && _ctx.oembedHtml)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              innerHTML: _ctx.oembedHtml,
              class: "vimeo-embed"
            }, null, 8, _hoisted_4))
          : (_openBlock(), _createElementBlock("iframe", {
              key: 1,
              src: _ctx.embedSrc,
              frameborder: "0",
              class: "vimeo-embed",
              allowfullscreen: ""
            }, null, 8, _hoisted_5))
      ], 64))
}