
import CreatorContentMediaItem from "@/shared/components/CreatorContent/CreatorContentMediaItem.vue";
import LayoutEmptyPage from "@/shared/components/Layouts/LayoutEmptyPage.vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import ModalPrompt from "@/shared/components/ModalPrompt.vue";
import { useCreatorContentCart } from "@/shared/composables/useCreatorContentCart";
import { scrollToTop } from "@/shared/utils/browser";
import { config } from "@/shared/utils/config";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import routeNames from "@/web/router/routeNames";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {},
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    const {
      isCartEmpty,
      removeFromCart,
      clearCart,
      cartList,
      cartListLoading,
      cartListUpdating,
    } = useCreatorContentCart();

    const handleClearCart = () => {
      scrollToTop();
      clearCart();
    };

    const handleCheckout = async () => {
      router.push({ name: routeNames.creatorContentSummary });
    };

    return {
      t,
      config,
      routeNames,

      cartList,
      cartListLoading,
      cartListUpdating,
      isCartEmpty,

      removeFromCart,
      handleClearCart,
      handleCheckout,
    };
  },
  methods: { makeRequiredRule },
  components: {
    PageLayout,
    ModalPrompt,
    LayoutEmptyPage,
    CreatorContentMediaItem,
    LoadingOutlined,
  },
});
