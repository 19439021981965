
import { defineComponent, watchEffect, PropType, ref } from "vue";
import { ReloadOutlined } from "@ant-design/icons-vue";
import { useI18n } from "vue-i18n";
import {
  VimeoOembedOptions,
  VimeoVideoStatus,
  getVimeoEmbedHtml,
} from "@/shared/utils/vimeo";
import { checkURLHost } from "@/shared/utils/browser";

export const isValidVimeoUrl = (url: string) => checkURLHost(url, "vimeo.com");

export default defineComponent({
  props: {
    embedSrc: {
      type: String,
      required: true,
    },
    useOembed: Boolean,
    oembedOptions: Object as PropType<VimeoOembedOptions>,
    status: {
      type: String as PropType<VimeoVideoStatus>,
      default: "available",
    },
    hideRefresh: Boolean,
  },
  emits: ["on-refresh"],
  setup(props) {
    const { t } = useI18n();

    const oembedHtml = ref("");

    watchEffect(async () => {
      if (props.useOembed) {
        oembedHtml.value = await getVimeoEmbedHtml(
          props.embedSrc,
          props.oembedOptions
        );
      }
    });

    return { t, oembedHtml };
  },
  components: {
    ReloadOutlined,
  },
});
